.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  padding: 0.6rem !important;
}
/* Custom styles for dark theme */
.select2-selection__control {
  background-color: transparent !important;
  border-color: #3a445b !important;
  box-shadow: none !important;
}
.select2-selection__single-value {
  color: #fff !important;
}

.select2-selection__menu {
  background-color: #444;
  color: #fff;
}

.select2-selection__menu-list {
  background-color: #444;
  color: #fff;
}

.select2-selection__option {
  background-color: #444;
  color: #fff;
}

.select2-selection__option--is-focused {
  background-color: #555;
}

.select2-selection__option--is-selected {
  background-color: #666;
}
.select2-selection__option:hover {
  background-color: #61adff;
  color: #fff;
}
.select2-selection__control
  .select2-selection__control--is-focused
  .select2-selection__control--menu-is-open
  .css-t3ipsp-control {
  display: block !important;
}
.border.custom-button:hover {
  border-color: grey !important;
  background-color: var(--bs-primary) !important;
}
.border.clear-button {
  background-color: #796fbe10;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}
.border.clear-button:hover {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: #dcdcdc !important;
}
